import React, { useState, useEffect } from 'react';
import {
  ListMusic,
  PlusCircle,
  Edit,
  Trash2,
  Play,
  Clock,
  UserPlus,
  Calendar,
  Save,
  SquareX,
  Boxes,
  RefreshCcw,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import mainApi from '../../utils/MainApi';
import ItemsModal from '../../components/ItemsModel/ItemsModal';
import showToast from '../../utils/toast';
import { handleMainError } from '../../utils/errors/errorHandlers';
import Modal from '../../components/Modal/Modal';
import './PlaylistBrowsePage.css'; // Make sure to create this CSS file
import DeviceList from '../../components/Device/DeviceList/DeviceList';
import EditableText from '../../components/EditableText/EditableText';
import PlaylistForm from '../../components/PlaylistForm/PlaylistForm';
const PlaylistBrowsePage = ({
  onloadDevicesList,
  onAssignPlaylistToDevice,
  onUploadSubmit,
  onPayngoItemFetch,
}) => {
  const [isPlaylistAddModalOpen, setIsPlaylistAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [playlistData, setPlaylistData] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isPlaylistEditMode, setIsPlaylistEditMode] = useState(false);

  // models
  const [isItemPopupOpen, setIsItemPopupOpen] = useState(false);
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [playlistItemEdited, setPlaylistItemEdited] = useState(null);
  const [playlistItemEditId, setPlaylistItemEditId] = useState(null);
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await onloadDevicesList();
        setDeviceList(devices.verified);
      } catch (error) {
        console.error('Error fetching devices', error);
      }
    };
    getDevices();
  }, [isDeviceModalOpen]);
  useEffect(() => {
    document.title = 'Splash: Playlist Browser';

    const fetchPlaylists = async () => {
      try {
        setIsLoading(true);
        const response = await mainApi.getAllPlaylist();
        setPlaylistData(response);
      } catch (err) {
        handleMainError(err, true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaylists();

    return () => {
      document.title = 'Splash';
    };
  }, []);

  useEffect(() => {
    console.log(playlistData);

    setSelectedPlaylist((prevPlaylist) => {
      if (prevPlaylist) {
        return playlistData.find(
          (playlist) => playlist._id === prevPlaylist._id
        );
      }
      return null;
    });
  }, [playlistData]);

  const handleDeletePlaylistItem = async (playlistId, itemId) => {
    try {
      await mainApi.deletePlaylistItem(playlistId, itemId);
      setPlaylistData((prevPlaylists) =>
        prevPlaylists.map((playlist) =>
          playlist._id === playlistId
            ? {
                ...playlist,
                items: playlist.items.filter((item) => item._id !== itemId),
              }
            : playlist
        )
      );

      showToast('הפריט נמחק מהפלייליסט');
      return true;
    } catch (error) {
      handleMainError(error, true);
      return false;
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: 'numeric',
    });
  };

  const handleEditItem = (item) => {
    // Implement edit logic here
    console.log(`Editing item:`, item);
  };
  const setPlaylistDataHandler = (playlistId, newItems) => {
    console.log(playlistId, newItems);

    setPlaylistData((prevPlaylist) => {
      const newPlaylist = prevPlaylist.map((playlist) => {
        if (playlist._id === playlistId) {
          return {
            ...playlist,
            items: newItems,
          };
        }
        return playlist;
      });
      return newPlaylist;
    });
  };

  const handleItemsChange = (playlistId, newItems) => {
    console.log(newItems);
    setPlaylistDataHandler(playlistId, newItems);
  };

  const handleAddItem = async (itemData) => {
    try {
      const res = await mainApi.addPlaylistItem(selectedPlaylist._id, itemData);
      setPlaylistData((prevPlaylists) =>
        prevPlaylists.map((playlist) =>
          playlist._id === selectedPlaylist._id
            ? { ...playlist, items: [...playlist.items, res] }
            : playlist
        )
      );
      return selectedPlaylist._id;
      showToast('הפריט נוסף לפלייליסט בהצלחה', 'success');
      return true;
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleSaveOrder = async (newItems) => {
    // Implement save order logic here
    console.log(`Saving new order:`, newItems);

    // Update the selectedPlaylist with the new order
    setSelectedPlaylist((prevPlaylist) => ({
      ...prevPlaylist,
      items: newItems,
    }));

    try {
      const res = await mainApi.reorderPlaylistItems(
        selectedPlaylist._id,
        newItems
      );

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log('Selected Playlist:', selectedPlaylist);
  }, [selectedPlaylist]);
  const handleItemsClick = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsPlaylistModalOpen(true);
  };

  const handleAssignDeviceSelect = (playlist) => {
    console.log(playlist);

    setSelectedPlaylist(playlist);
    setIsDeviceModalOpen(true);
  };
  const handleSelectDevice = (id) => {
    console.log(id, selectedPlaylist);

    onAssignPlaylistToDevice(id, selectedPlaylist._id);
  };
  const handleCloseModal = () => {
    setIsPlaylistModalOpen(false);
    setIsDeviceModalOpen(false);
    setSelectedPlaylist(null);
  };

  const handleEditPlaylistItem = async () => {
    try {
      const updatedPlaylist = await mainApi.updatePlaylist(
        playlistItemEdited.id,
        playlistItemEdited
      );

      setPlaylistData((prevPlaylists) =>
        prevPlaylists.map((playlist) =>
          playlist._id === playlistItemEdited.id ? updatedPlaylist : playlist
        )
      );
      showToast('פלייליסט עודכן בהצלחה', 'success');
    } catch (error) {
      handleMainError(error, true);
    } finally {
      setIsPlaylistEditMode(false);
      setPlaylistItemEdited(null);
      setPlaylistItemEditId(null);
    }
  };

  const handlePlaylistDelete = async (playlistId) => {
    try {
      await mainApi.deletePlaylistById(playlistId);
      setPlaylistData((prevPlaylists) =>
        prevPlaylists.filter((playlist) => playlist._id !== playlistId)
      );
      showToast('הפלייליסט נמחק בהצלחה', 'success');
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handlePlaylistTextChange = (text, name, id) => {
    setPlaylistItemEdited((prev) => ({
      ...prev,
      [name]: text,
      id: id,
    }));
  };

  const handleSubmitNewPlaylist = async (playlistData) => {
    try {
      const newPlaylist = await mainApi.createPlaylist(playlistData);
      setPlaylistData((prevPlaylists) => [...prevPlaylists, newPlaylist]);
      setIsPlaylistAddModalOpen(false);
      showToast('הפלייליסט נוצר בהצלחה', 'success');
    } catch (error) {
      handleMainError(error, true);
    }
  };

  useEffect(() => {
    if (playlistItemEdited) {
      console.log(playlistItemEdited);
    }
  }, [playlistItemEdited]);
  const renderHeaderAndAddPlaylist = () => {
    return (
      <div className="page-header">
        <h1 className="page-title">פלייליסטים</h1>
        <button
          className="btn btn-add-playlist"
          onClick={() => {
            setIsPlaylistAddModalOpen(true);
          }}
        >
          <PlusCircle className="btn-icon btn-icon-add-playlist" />
        </button>
      </div>
    );
  };
  const buttonVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };
  const renderLoading = () => {
    return (
      <div className="loading-container">
        <ListMusic className="loading-icon" />
        <h2 className="loading-text">טוען פלייליסטים...</h2>
      </div>
    );
  };

  const renderPlaylist = () => {
    return (
      <div className="playlist-list">
        <ul className="playlist-items">
          {playlistData.map((playlist) => (
            <li key={playlist._id} className="playlist-item">
              <div className="playlist-content">
                <div className="playlist-header">
                  <EditableText
                    name={'name'}
                    id={playlist._id}
                    onTextChange={handlePlaylistTextChange}
                    text={playlist.name}
                    editMode={
                      isPlaylistEditMode && playlistItemEditId === playlist._id
                    }
                  />
                  <div className="playlist-status">
                    <p
                      className={`status-badge ${
                        playlist.isActive ? 'active' : 'inactive'
                      }`}
                    >
                      {playlist.isActive ? 'פעיל' : 'לא פעיל'}
                    </p>
                  </div>
                </div>
                <div className="playlist-details">
                  <div className="playlist-info">
                    <p className="playlist-duration">
                      <UserPlus className="info-icon" />
                      {playlist?.createdBy?.username}
                    </p>

                    <p className="playlist-duration">
                      <Clock className="info-icon" />
                      {playlist.totalDuration} שניות
                    </p>
                  </div>
                  <div className="playlist-date">
                    <Calendar className="info-icon" />
                    <p>{formatDate(playlist.createdAt)}</p>
                  </div>
                  {playlist?.lastUpdatedBy?.username && (
                    <div className="playlist-date">
                      <RefreshCcw className="info-icon" />
                      <p>
                        {`${formatDate(playlist.israelTimestamp)}`}{' '}
                        <strong>{`by - ${playlist.lastUpdatedBy.username} `}</strong>
                      </p>
                    </div>
                  )}
                </div>
                <div className="playlist-actions">
                  {isPlaylistEditMode && playlistItemEditId === playlist._id ? (
                    <>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          setIsPlaylistEditMode(false);
                          setPlaylistItemEdited(null);
                          setPlaylistItemEditId(null);
                        }}
                      >
                        <SquareX className="btn-icon" />
                        ביטול
                      </button>
                      {playlistItemEdited && (
                        <button
                          className="btn btn-secondary"
                          onClick={handleEditPlaylistItem}
                        >
                          <Save className="btn-icon" />
                          שמור
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleItemsClick(playlist)}
                      >
                        <Boxes className="btn-icon" />
                        {playlist.items.length} פריטים
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleAssignDeviceSelect(playlist)}
                      >
                        <Play className="btn-icon" />
                        הצמד
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          setIsPlaylistEditMode(true);
                          setPlaylistItemEditId(playlist._id);
                        }}
                      >
                        <Edit className="btn-icon" />
                        ערוך
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          handlePlaylistDelete(playlist._id);
                        }}
                      >
                        <Trash2 className="btn-icon" />
                        מחק
                      </button>
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <>
      <Modal
        isOpen={isDeviceModalOpen}
        onClose={handleCloseModal}
        zIndex={100}
        title="בחר מכשיר להצמדה"
        footer=""
      >
        <DeviceList
          deviceList={deviceList}
          deviceType="select"
          onSelectedItem={handleSelectDevice}
        />
      </Modal>

      <Modal
        isOpen={isPlaylistAddModalOpen}
        onClose={() => setIsPlaylistAddModalOpen(false)}
        zIndex={100}
        title={'הוסף פלייליסט'}
        footer=""
      >
        <PlaylistForm onSubmit={handleSubmitNewPlaylist} />
      </Modal>
      <div className="playlist-browse-page">
        {renderHeaderAndAddPlaylist()}
        {playlistData.length === 0 && !isLoading && (
          <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg shadow-inner">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-700 mb-2">
                אין פריטים ברשימה
              </h2>
              <p className="text-gray-500">הוסף פריטים חדשים כדי להתחיל</p>
            </div>
          </div>
        )}
        {isLoading ? renderLoading() : renderPlaylist()}

        <ItemsModal
          isOpen={isPlaylistModalOpen}
          onClose={handleCloseModal}
          items={selectedPlaylist ? selectedPlaylist.items : []}
          playlistId={selectedPlaylist ? selectedPlaylist._id : null}
          onEditItem={handleEditItem}
          onAddItem={handleAddItem}
          onSaveOrder={handleSaveOrder}
          onUploadSubmit={onUploadSubmit}
          onPayngoItemFetch={onPayngoItemFetch}
          onDeleteItem={handleDeletePlaylistItem}
          onItemsChange={handleItemsChange}
        />
      </div>
    </>
  );
};

export default PlaylistBrowsePage;
