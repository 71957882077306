import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import './PlaylistAddItemForm.css';
import { Check } from 'lucide-react';
import RecentItemCard from '../RecentItemCard/RecentItemCard';

const PlaylistAddItemForm = ({ item, type, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      itemType: type,
      item: '',
      name: '',
      duration: '',
      order: '',
    },
  });

  const handleOnSubmit = (form) => {
    const data = {
      ...form,
      item: item._id,
    };
    onSubmit(data);
  };

  const StyledLabel = ({ label, value }) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        className="inline-flex items-center space-x-2 bg-gray-100 rounded-full px-3 py-1 hover:bg-gray-200 transition-colors duration-200 rtl"
      >
        <span className="text-sm font-medium text-gray-600">{label}: </span>
        <motion.span
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2, delay: 0.1 }}
          className="text-sm font-bold text-gray-800"
        >
          {value}
        </motion.span>
      </motion.div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg playlist-add-item-form rtl"
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="playlist-item-add-form"
      >
        <div className="playlist-input-warper">
          <Controller
            name="name"
            control={control}
            rules={{ required: 'נא הכנס שם' }}
            render={({ field }) => (
              <div>
                <input
                  {...field}
                  type="text"
                  placeholder="שם"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.name.message}
                  </p>
                )}
              </div>
            )}
          />

          <Controller
            name="duration"
            control={control}
            rules={{
              required: 'משך זמן נדרש',
              min: { value: 1, message: 'משך זמן חייב להיות גדול מ-0' },
              validate: (value) =>
                Number.isInteger(Number(value)) ||
                'משך זמן חייב להיות מספר שלם',
            }}
            render={({ field }) => (
              <div>
                <input
                  {...field}
                  type="number"
                  placeholder="משך זמן (שניות)"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.duration && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.duration.message}
                  </p>
                )}
              </div>
            )}
          />
        </div>

        <RecentItemCard
          className={'playlist-add-item-form-card'}
          cardType="none"
          item={item}
          type={`${item.hasOwnProperty('templateId') ? 'Template' : 'Asset'}`}
        />

        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="w-full"
        >
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition-colors duration-300 playlist-add-item-form-button"
          >
            <Check size={28} />
            הוסף
          </button>
        </motion.div>
      </form>

      {Object.keys(errors).length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md"
        >
          <p>נא תקן שגיאות לפני שליחה</p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default PlaylistAddItemForm;
