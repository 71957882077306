import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import mainApi from '../utils/MainApi';
import { handleMainError } from '../utils/errors/errorHandlers';
import UnauthorizedError from '../utils/errors/UnauthorizedError';
import { toast, Slide } from 'react-toastify';
export const AuthStatusContext = createContext(null);

export const AuthStatusProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(decodedToken.exp > Date.now() / 1000);
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []);

  const login = useCallback(async (loginData) => {
    try {
      const response = await mainApi.signin(loginData);
      const newToken = response.token;
      localStorage.setItem('jwt', newToken);
      setIsAuthenticated(true);
      return newToken;
    } catch ({ message }) {
      handleMainError(new UnauthorizedError(message));
 
      throw new UnauthorizedError(message);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('jwt');
    setIsAuthenticated(false);
  }, []);

  return (
    <AuthStatusContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, login, logout }}
    >
      {!loading && children}
    </AuthStatusContext.Provider>
  );
};
