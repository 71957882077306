import React, { useState, useEffect } from 'react';
import { ListMusic, PlusCircle, Edit, Trash2, Play } from 'lucide-react';
import { Link } from 'react-router-dom';
import mainApi from '../../utils/MainApi'; // Adjust the import path as needed
import { handleMainError } from '../../utils/errors/errorHandlers';
const PlaylistCreatorPage = () => {
 
  return (
    <span >asd</span>
  );
};

export default PlaylistCreatorPage;
