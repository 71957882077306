import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthProvider';
import { AuthStatusContext } from '../../contexts/AuthStatus';

const ProtectedRoute = ({ permissions }) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useContext(AuthStatusContext);
  const { currentUser } = useContext(AuthContext);

  // Check if the user is authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ goBackToPathName: pathname }} />;
  }

  // Check if the user has the required permissions
  const hasPermission = permissions.every((permission) =>
    currentUser?.role?.permissions?.includes(permission)
  );

  if (!hasPermission) {
    console.log('Unauthorized access attempt:', pathname);
    return;
/*     return <Navigate to="/unauthorized" />; */
  }

  // Render the protected content
  return <Outlet />;
};

export default ProtectedRoute;