import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Music, FileText } from 'lucide-react';
import './PlaylistForm.css';

const PlaylistForm = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const handleFormSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <motion.form
      variants={formVariants}
      initial="hidden"
      animate="visible"
      onSubmit={handleSubmit(handleFormSubmit)}
      className="playlist-form"
    >
      <motion.div variants={itemVariants} className="form-group">
        <label className="label">
          <Music className="icon" />
          שם
        </label>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'שם פלייליסט נדרש', maxLength: 100 }}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              placeholder="הכנס שם פלייליסט"
              className="input"
            />
          )}
        />
        {errors.name && <span className="error">{errors.name.message}</span>}
      </motion.div>

      <motion.div variants={itemVariants} className="form-group">
        <label className="label">
          <FileText className="icon" />
          תיאור
        </label>
        <Controller
          name="description"
          control={control}
          rules={{ maxLength: 500 }}
          render={({ field }) => (
            <textarea
              {...field}
              placeholder="הכנס תיאור"
              className="textarea"
              rows="2"
            />
          )}
        />
        {errors.description && (
          <span className="error">{errors.description.message}</span>
        )}
      </motion.div>

      <motion.button
        type="submit"
        className="submit-button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        צור פלייליסט
      </motion.button>
    </motion.form>
  );
};

export default PlaylistForm;