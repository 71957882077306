import React, { useEffect, useRef, useCallback } from 'react';
import { X } from 'lucide-react';
import './Modal.css';
const Modal = ({ isOpen, onClose, title, children, footer, zIndex }) => {
  const modalRef = useRef(null);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen, handleKeyDown, handleClickOutside]);

  useEffect(() => {
    if (isOpen) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusableElements.length) focusableElements[0].focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={{zIndex:zIndex}}  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 model-container">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-xl w-full mx-4 md:mx-0 model"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex justify-between items-center border-b p-4 model-header">
          <h3 className="text-lg font-semibold">{title}</h3>

          <X style={{ cursor: 'pointer' }} size={24} onClick={onClose} />
        </div>
        <div className="children-model p-4">{children}</div>
        {footer && <div className="border-t p-4">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
