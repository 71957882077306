import React from "react";
import "./PagePreloader.css";

export default function PagePreloader() {
  return (
    <div className={"page-preloader"}>
      <i className={"page-preloader__spinner"}></i>
    </div>
  );
}
