import React, { useEffect, useState } from 'react';
import {
  FileIcon,
  Image,
  Film,
  Calendar,
  HardDrive,
  FileType,
  pencil,
} from 'lucide-react';

const FilePreview = ({
  filePreviewUrl,
  fileName,
  fileSize,
  fileTimestamp,
  fileType,
}) => {
    const [fileTypeIcon, setFileTypeIcon] = useState('');
  useEffect(() => {
    if (fileType.startsWith('image')) {
      setFileTypeIcon(<Image className="w-5 h-5 text-pink-500 mr-2"/>);
    } else if (fileType.startsWith('video')) {
      setFileTypeIcon(<Film className="w-5 h-5 text-indigo-500 mr-2"/>);
    } else {
      setFileTypeIcon('file');
    }
  }, [fileType]);
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-4">
        <span className="text-sm text-gray-600">{fileName}</span>
        <h2 className="text-2xl font-bold text-gray-800">תצוגה מקדימה</h2>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="flex items-center">
          <HardDrive className="w-5 h-5 text-blue-500 mr-2" />
          <div>
            <p className="text-xs text-gray-500">גודל</p>
            <p className="text-sm font-medium">{fileSize}</p>
          </div>
        </div>
        <div className="flex items-center">
          <Calendar className="w-5 h-5 text-green-500 mr-2" />
          <div>
            <p className="text-xs text-gray-500">נוסף</p>
            <p className="text-sm font-medium">{fileTimestamp}</p>
          </div>
        </div>
        <div className="flex items-center">
          {fileTypeIcon}
          <div>
            <p className="text-xs text-gray-500">סוג</p>
            <p className="text-sm font-medium">{fileType}</p>
          </div>
        </div>
      </div>

      {filePreviewUrl && (
        <div className="rounded-lg overflow-hidden">
          {fileType.startsWith('image') ? (
            <div className="relative pt-[56.25%]">
              <img
                src={filePreviewUrl}
                alt="Preview"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          ) : fileType.startsWith('video') ? (
            <div className="relative pt-[56.25%]">
              <video
                controls
                className="absolute top-0 left-0 w-full h-full object-cover"
              >
                <source src={filePreviewUrl} type={fileType} />
                הדפדפן שהינך משתמש אינו תומך בוידאו
              </video>
            </div>
          ) : (
            <div className="flex items-center justify-center bg-gray-100 py-12">
              <FileIcon className="w-16 h-16 text-gray-400" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilePreview;
