import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Eye, Trash2, User, Check } from 'lucide-react';
import PriceSwitcher from '../PriceSwitcher/PriceSwitcher';
import './RecentItemCard.css';
const RecentItemCard = ({
  item,
  type,
  onDelete,
  cardType,
  onSelect,
  className,
  imgClassName,
}) => {
  const formattedDate = new Date(item.createdAt).toLocaleDateString('he-IL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handleSelectItem = (item) => {
    onSelect(item);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onDelete(item._id);
  };

  useEffect(() => {
    console.log(type);
  }, [cardType]);

  return (
    <div
      className={`bg-white shadow rounded-lg p-4 w-full max-w-sm hover:shadow-lg transition-shadow duration-300 relative group recent-item-card ${className}`}
    >
      <div
        className="flex items-center justify-between p-2 bg-gray-50 rounded-t-lg shadow-sm resct-item1"
        style={{ direction: 'rtl' }}
      >
        <User size={24} className="text-gray-500 " />
        <span className="text-lg font-medium text-gray-700 truncate max-w-[200px]">
          {item?.uploadedByName || 'Unknown User'}
        </span>
        {cardType === 'select' && (
          <button
            onClick={() => handleSelectItem(item)}
            className="p-1.5 rounded-full   hover:text-green-500 hover:bg-green-100 transition-colors duration-200"
            aria-label="Delete item"
          >
            <Check size={20} />
          </button>
        )}
        {cardType === 'delete' && (
          <button
            onClick={handleDelete}
            className="p-1.5 rounded-full  hover:text-red-500 hover:bg-red-100 transition-colors duration-200"
            aria-label="Delete item"
          >
            <Trash2 size={20} />
          </button>
        )}
      </div>

      {item.title && (
        <div className="mb-4">
          <h3
            className="font-semibold text-lg truncate resct-item12"
            title={item.title}
          >
            {item.title}
          </h3>
        </div>
      )}

      {item.description && (
        <div className="mb-4">
          {type.toLowerCase() !== 'asset' && (
            <p className="text-sm text-gray-600 mb-4 line-clamp-2">
              {item.description}
            </p>
          )}
        </div>
      )}
      <div
        style={{
          direction: 'rtl',
          textAlign: 'center',
          gap: '10px',
          flexDirection: 'column',
        }}
        className="flex justify-between items-center"
      >
        {type.toLowerCase() === 'asset' && item.mediaType === 'image' && (
          <img
            src={item.mediaUrl}
            alt={item.title}
            className={`recent-item-card__image ${imgClassName}`}
          />
        )}

        {type.toLowerCase() === 'asset' && item.mediaType === 'video' && (
          <video controls className="recent-item-card__image">
            <source src={item.mediaUrl} type={`video/${item.extension}`} />
            הדפדפן לא תומך בוידאו
          </video>
        )}

        {type.toUpperCase() === 'Template' && (
          <div className={`template `}>
            {item.fileType === 'video' && (
              <video src={item.url} autoPlay loop muted className="videoTag" />
            )}
            {item.fileType === 'image' && (
              <img
                src={item.url}
                alt="Displayed content"
                className="imageTag"
              />
            )}

            <div className="template__image-list  animated-background"></div>
            {item.title && <h1 className="title">{item.title}</h1>}
            {/*     <div className="price">
    <span className="newPrice">₪{fileDetails.newPrice}</span>
    <span className="oldPrice">₪{fileDetails.oldPrice}</span>
  </div> */}
            <PriceSwitcher fileDetails={item} />

            <p className="text">{item.text}</p>
          </div>
        )}

        {cardType != 'select' && (
          <Link
            to={`/c/resource/${type}s/${item._id}`}
            className="inline-flex items-center text-blue-500 hover:text-blue-700"
            title="צפה בפרטים"
          >
            <Eye className="ml-2 h-6 w-6" />

            <span>צפה בפרטים</span>
          </Link>
        )}
        <div
          className="flex items-center flex- text-sm text-gray-500"
          title={formattedDate}
        >
          <Calendar className="ml-1 h-6 w-6" />
          <span>{formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default RecentItemCard;
