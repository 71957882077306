import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import TokenError from '../utils/errors/TokenError';
import { handleMainError } from '../utils/errors/errorHandlers';
import mainApi from '../utils/MainApi';
import { AuthStatusContext } from './AuthStatus';
import PagePreloader from '../components/PagePreloader/PagePreloader';  
export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('jwt'));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthStatusContext);

  const isJwtTokenExpired = useCallback((token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp < Date.now() / 1000;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  }, []);

  const handleSignOut = useCallback(() => {
    logout();
    setToken(null);
    setCurrentUser(null);
    navigate('/login');
  }, [logout, navigate]);

  const fetchUserInfo = useCallback(async (token) => {
    try {
      const res = await mainApi.getUserInfo(token);
      if (res && res._id) {
        setCurrentUser(res);
      } else {
        throw new Error('Invalid user data received');
      }
    } catch (error) {
      logout();

      handleMainError(new TokenError('אסימון לא חוקי'));

      throw error;
    }
  }, []);

  useEffect(() => {
    const validateAndFetchUser = async () => {
      console.log('Token:', token);

      try {
        if (!token) {
          console.warn('Token is missing');

          return;
        }
        if (isJwtTokenExpired(token)) {
          throw TokenError.expired();
        }
        await fetchUserInfo(token);
      } catch ({ message }) {
        handleMainError(message, setCurrentUser, navigate);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      validateAndFetchUser();
    } else {
      setLoading(false);
    }
  }, [token, isJwtTokenExpired, fetchUserInfo, navigate, isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      currentUser,
      token,
      handleSignOut,
    }),
    [currentUser, token, handleSignOut]
  );

  if (loading) {
    return <PagePreloader />; // Or your custom loading component
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
