function detectFileType(filePath) {
  // Define the allowed extensions for images and videos
  function convertToEmbedURL(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?]*).*/;
    const match = url.match(regex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
  }

  if (!filePath) return { type: "unsupported", extension: "" };
  if (filePath.includes("youtu.be/")) {
    return {
      type: "video",
      extension: "youtube",
      url: convertToEmbedURL(filePath),
    };
  }
  if (filePath.includes("youtube.") || filePath.includes("youtu.be/")) {
    return { type: "video", extension: "youtube", url: filePath };
  }

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = [
    "mp4",
    "mov",
    "wmv",
    "flv",
    "avi",
    "avchd",
    "webm",
    "mkv",
  ];
  console.log(filePath);

  // Extract the file extension from the path
  const extension = filePath.split(".").pop().toLowerCase();

  // Check the extension against the allowed types
  if (imageExtensions.includes(extension)) {
    return { type: "image", extension, url: filePath };
  } else if (videoExtensions.includes(extension)) {
    return { type: "video", extension, url: filePath };
  } else {
    return { type: "unsupported", extension, url: filePath };
  }
}

module.exports = { detectFileType };
