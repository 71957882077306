import React, { useRef } from 'react';
import { Plus, FolderPlus, ArrowLeft } from 'lucide-react';
import './FullPageActionCards.css';

const ActionCard = ({ icon: Icon, text, description, onClick, scrollRef }) => {
  return (
    <button
      onClick={() => onClick(scrollRef)}
      className="action-card"
      ref={scrollRef}
    >
      <div className="card-gradient" />
      <Icon className="card-icon" />
      <h2 className="card-title">{text}</h2>
      <p className="card-description">{description}</p>
      {/*     <div className="card-action">
        <span className="card-action-text">התחל עכשיו</span>
        <ArrowLeft className="card-action-icon" />
      </div> */}
    </button>
  );
};

const FullPageActionCards = ({ onClickCreateNew, onClickAddExisting }) => {
  const addExistingRef = useRef(null);
  const createNewRef = useRef(null);

  const handleCardClick = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleAddExisting = () => {
    onClickAddExisting();
  };

  const handleCreateNew = () => {
    onClickCreateNew();
  };

  return (
    <div className="full-page-container">
      <div className="cards-container">
        <div className="card-wrapper animate-slide-in-left">
          <ActionCard
            icon={FolderPlus}
            text="הוסף מפריטים קיימים"
            description="ייבא ושמש פריטים קיימים מהאוסף שלך."
            onClick={() => {
              handleCardClick(addExistingRef);
              handleAddExisting();
            }}
            scrollRef={addExistingRef}
          />
        </div>
        <div className="card-wrapper animate-slide-in-right">
          <ActionCard
            icon={Plus}
            text="צור פריט חדש"
            description="התחל מחדש וצור פריט חדש לגמרי."
            onClick={() => {
              handleCardClick(createNewRef);
              handleCreateNew();
            }}
            scrollRef={createNewRef}
          />
        </div>
      </div>
    </div>
  );
};

export default FullPageActionCards;
