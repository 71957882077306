// src/utils/errorHandlers.js

import { toast, Slide } from 'react-toastify';
import NetworkError from './NetworkError';
import TokenError from './TokenError';
import ValidationError from './ValidationError';
import NotFoundError from './NotFoundError';
import UnauthorizedError from './UnauthorizedError';
import showToast from '../toast';
export const handleMainError = (error, showError = false) => {
  let errorMessage;

  switch (true) {
    case error instanceof TokenError:
      errorMessage = error.message || ' אסימון לא חוקי. אנא התחבר מחדש.';
      break;
    case error instanceof NetworkError:
      errorMessage = 'שגיאת רשת. אנא בדוק את החיבור שלך ונסה שוב.';
      break;
    case error instanceof ValidationError:
      errorMessage = error.message;
      break;
    case error instanceof NotFoundError || error.status === 404:
      errorMessage = error.message || 'התוכן המבוקש אינו נמצא.';
      break;

    case error instanceof UnauthorizedError:
      errorMessage = error.message || 'אין לך הרשאה לבצע פעולה זו.';
      break;
      case error instanceof Error:
      errorMessage = error.message;
      break;
    default:
      errorMessage = 'אירעה שגיאה בלתי צפויה. אנא נסה שוב.';
  }

  console.error('MyError:', error);

  if (showError) {
    showToast(errorMessage, 'error');
  }

  return errorMessage;
};

export { TokenError, NetworkError, ValidationError, NotFoundError };
