// config.js


const ENV = process.env.NODE_ENV || 'production'; // Changed default to 'production'

const CONFIG = {
  development: {
    API_URL: 'http://192.168.68.114:3001',
    APP_URL: 'http://192.168.68.114:3000'
  },
  production: {
    API_URL: 'https://api.s.saharm.com',
    APP_URL: 'https://s.saharm.com'
  }
};

const { API_URL, APP_URL } = CONFIG[ENV] || CONFIG.production; // Fallback to production if ENV is invalid

module.exports = {
  API_URL,
  APP_URL
};