import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Search,
  X,
  Image,
  Video,
  Layout,
  Calendar,
  File,
  HardDrive,
  Check,
} from 'lucide-react';
import './MediaFilterComponent.css';

const mediaTypes = [
  { type: 'All', text: 'הכול' },
  { type: 'Image', text: 'תמונות' },
  { type: 'Video', text: 'וידאו' },
  { type: 'Template', text: 'תבניות' },
];
const sortOptions = ['החדש ביותר', 'הישן ביותר'];

const ImageNotFound = () => (
  <svg
    className="image-not-found"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <circle cx="8.5" cy="8.5" r="1.5" />
    <polyline points="21 15 16 10 5 21" />
  </svg>
);

const MediaFilterComponent = ({ mediaItems = [], onSelect }) => {
  const [activeFilter, setActiveFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('החדש ביותר');

  const filteredAndSortedItems = useMemo(() => {
    if (!Array.isArray(mediaItems)) return [];

    let items = mediaItems.filter((item) => {
      if (!item) return false;
      const isTemplate = item.templateId !== undefined;
      const matchesType =
        activeFilter === 'All' ||
        (activeFilter === 'Template' && isTemplate) ||
        (item.mediaType &&
          item.mediaType.toLowerCase() === activeFilter.toLowerCase());
      const matchesSearch =
        (item.tags &&
          Array.isArray(item.tags) &&
          item.tags.some((tag) =>
            tag.toLowerCase().includes(searchTerm.toLowerCase())
          )) ||
        (item.uploadedByName &&
          item.uploadedByName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (item.title &&
          item.title.toLowerCase().includes(searchTerm.toLowerCase()));
      return matchesType && matchesSearch;
    });

    items.sort((a, b) => {
      switch (sortBy) {
        case 'החדש ביותר':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'הישן ביותר':
          return new Date(a.createdAt) - new Date(b.createdAt);
        default:
          return 0;
      }
    });

    return items;
  }, [mediaItems, activeFilter, searchTerm, sortBy]);

  if (!Array.isArray(mediaItems)) {
    return <div className="error-message">Error: Invalid media items data</div>;
  }

  const getIcon = (type) => {
    switch (type) {
      case 'Image':
        return <Image size={18} />;
      case 'Video':
        return <Video size={18} />;
      case 'Template':
        return <Layout size={18} />;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('he-IL', options);
  };

  return (
    <div className="media-filter-container">
      <div className="filter-controls">
        <div className="filter-buttons">
          {mediaTypes.map((type) => (
            <motion.button
              key={type.type}
              className={`filter-button ${
                activeFilter === type.type ? 'active' : ''
              }`}
              onClick={() => setActiveFilter(type.type)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {getIcon(type.type)}
              <span>{type.text}</span>
            </motion.button>
          ))}
        </div>
        <div className="search-sort">
          <div className="search-container">
            <input
              type="text"
              placeholder="חיפוש"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <Search className="search-icon" size={18} />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="clear-search"
              >
                <X size={18} />
              </button>
            )}
          </div>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="sort-select"
          >
            {sortOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <motion.div layout className="media-grid">
        <AnimatePresence>
          {filteredAndSortedItems.map((item) => (
            <motion.div
              key={item._id}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="media-item"
            >
              <div className="media-preview-button-container">
                <button
                  onClick={() => onSelect(item)}
                  className="p-1.5 rounded-full   hover:text-green-500 hover:bg-green-100 transition-colors duration-200 media-preview-select-item"
                  aria-label="select item"
                >
                  <Check size={24} />
                </button>
              </div>
              <div className="media-preview">
                {item.templateId ? (
                  <div className="template-preview">
                    <Layout size={48} className="template-icon" />
                  </div>
                ) : item.mediaType === 'image' ? (
                  <div className="image-container">
                    {item.mediaUrl ? (
                      <img
                        src={item.mediaUrl}
                        alt={item.uploadedByName}
                        className="media-image"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.style.display = 'none';
                          e.target.nextSibling.style.display = 'block';
                        }}
                      />
                    ) : null}
                    <div
                      className={`image-not-found-container ${
                        item.mediaUrl ? 'hidden' : ''
                      }`}
                    >
                      <ImageNotFound />
                    </div>
                  </div>
                ) : (
                  <video src={item.mediaUrl} className="media-video" controls />
                )}
              </div>
              <h3 className="media-title">
                {item.title || item.uploadedByName}
              </h3>
              <div className="media-info">
                <div className="date-info">
                  <Calendar size={14} />
                  <span>{formatDate(item.createdAt)}</span>
                </div>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="file-info"
              >
                {item.extension && (
                  <motion.div
                    className="file-extension"
                    whileHover={{ scale: 1.05 }}
                  >
                    <File size={14} />
                    <span>{item.extension}</span>
                  </motion.div>
                )}
                {item.sizeMB && (
                  <motion.div
                    className="file-size"
                    whileHover={{ scale: 1.05 }}
                  >
                    <HardDrive size={14} />
                    <span>{`${item.sizeMB} MB`}</span>
                  </motion.div>
                )}
              </motion.div>
              <div className="tag-container">
                {item.tags &&
                  item.tags.map((tag, index) => (
                    <motion.span
                      key={index}
                      className="tag"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.1 * index }}
                    >
                      {tag}
                    </motion.span>
                  ))}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default MediaFilterComponent;
