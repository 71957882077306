import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./PriceSwitcher.css";
function PriceSwitcher({ fileDetails }) {
  const [showOldPrice, setShowOldPrice] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowOldPrice((prev) => !prev);
    }, 1500); // change the price every 3 seconds

    return () => clearInterval(interval);
  }, []);

  
  if (!fileDetails.newPrice || !fileDetails.oldPrice) return null;

  return (
    <div className="price-container">
      <AnimatePresence>
        {showOldPrice ? (
          <motion.div
            key="old"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="price1 old-price1"
          >
            {fileDetails.oldPrice && `₪${fileDetails.oldPrice.trim()}`}
          </motion.div>
        ) : (
          <motion.div
            key="new"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="price1 new-price1"
          >
            {fileDetails.newPrice && `₪${fileDetails.newPrice.trim()}`}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default PriceSwitcher;
