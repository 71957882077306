/*eslint-disable*/
import React, { useState, useEffect } from 'react';

import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ConfigProvider } from 'antd';
import heIL from 'antd/locale/he_IL';
import 'dayjs/locale/he';
dayjs.locale('he'); // Hebrew locale
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const RangePicker = ({ onChange, onError }) => {
  const AntRangePicker = DatePicker.RangePicker;
  const now = dayjs();
  const defaultStartTime = now.add(5, 'minute');
  const [dates, setDates] = useState([defaultStartTime, null]);
  const [clientTimeZone, setClientTimeZone] = useState('');

  useEffect(() => {
    // Get the client's time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimeZone(timeZone);
  }, []);

  const disabledDate = (current) => {
    return current && current < now.subtract(1, 'day');
  };

  const disabledTime = (_, type) => {
    if (type === 'start') {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const handleNowClick = () => {
    const newStartTime = dayjs().add(5, 'minute');
    setDates([newStartTime, null]);
  };

  const handleDateChange = (values) => {
    if (values && values[0] && values[1] && values[1].isBefore(values[0])) {
      setDates([values[0], values[0]]);
    } else {
      setDates(values);
    }

    // Format the date values as requested
    const formattedDates = {
      start:
        values && values[0]
          ? {
              year: values[0].year(),
              month: values[0].month() + 1, // dayjs months are 0-indexed
              day: values[0].date(),
              hour: values[0].hour(),
              minutes: values[0].minute(),
              unixTime: values[0].unix(),
              utcTime: values[0].utc().format(),
              fullDateAndTime: values[0].format('YYYY-MM-DD HH:mm:ss'),
              timeZone: clientTimeZone,
              timeZoneOffset: values[0].format('Z'),
              localTime: values[0]
                .tz(clientTimeZone)
                .format('YYYY-MM-DD HH:mm:ss'),
            }
          : null,
      end:
        values && values[1]
          ? {
              year: values[1].year(),
              month: values[1].month() + 1, // dayjs months are 0-indexed
              day: values[1].date(),
              hour: values[1].hour(),
              minutes: values[1].minute(),
              unixTime: values[1].unix(),
              utcTime: values[1].utc().format(),
              fullDateAndTime: values[1].format('YYYY-MM-DD HH:mm:ss'),
              timeZone: clientTimeZone,
              timeZoneOffset: values[1].format('Z'),
              localTime: values[1]
                .tz(clientTimeZone)
                .format('YYYY-MM-DD HH:mm:ss'),
            }
          : null,
    };
    validedDateTimes(formattedDates);
  };

  const validedDateTimes = ({ start, end }) => {
    const now = dayjs();

    // Check if either start or end is null
    if (!start || !end) {
      // Do X - logic when either start or end is null
      onError('Either start or end is null.');
      console.log('Either start or end is null.');
      return;
    }

    // Check if start or end is less than the current time
    if (start?.unixTime < now.unix() || end?.unixTime < now.unix()) {
      // Do Y - logic when either start or end time is less than the current time
      onError('Start or end time is less than the current time.');
      console.log('Start or end time is less than the current time.');
      return;
    }
    return onChange({ start, end });
  };
  return (
    <Space direction="vertical" size={12}>
      <ConfigProvider direction="rtl" locale={heIL}>
        <AntRangePicker
          showTime={{
            format: 'HH:mm',
            hideDisabledOptions: true,
          }}
          format="DD/MM/YYYY HH:mm"
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          value={dates}
          showNow={true}
          onChange={handleDateChange}
        />
      </ConfigProvider>
    </Space>
  );
};

export default RangePicker;
