// src/utils/toast.js

import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 const showToast = (message, type = 'success') => {
  // Dismiss all existing toasts
  toast.dismiss();

  const toastOptions = {
    position: 'bottom-left',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Slide,
    rtl: true,
  };

  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warning(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};
 export default showToast;