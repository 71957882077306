  function convertBytesStringToMB(bytesString) {
    // Extract the number of bytes from the string
    const bytes = parseInt(bytesString, 10);

    // Convert bytes to MB
    const megabytes = bytes / 1048576;
 
    return   "mg " +megabytes.toFixed(2) ;
     // Formatting to 2 decimal places
  }


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function removeFileExtension(filePath) {
  return filePath.replace(/\.[^/.]+$/, "");
}

  module.exports = { convertBytesStringToMB, formatBytes, removeFileExtension };

  