import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Pencil } from 'lucide-react';
import './EditableText.css';
import { set } from 'react-hook-form';

const EditableText = ({
  text,
  placeholder = 'Enter text',
  textClassName,
  name,
  inputClassName,
  formClassName,
  containerClassName,
  editMode,
  onTextChange,
  id,
  children,
  onBlur,
  editIcon,
}) => {
  const [editableText, setEditableText] = useState(text);
  const [isEditing, setIsEditing] = useState(editMode);

  useEffect(() => {
    setEditableText(text);
    setIsEditing(editMode);
  }, [editMode, text]);

  const handleTextChange = (e) => {
    setEditableText(e.target.value);
    onTextChange(e.target.value, name, id);
  };

  return (
    <div name={name} className="flex justify-center items-center">
      <motion.div
        className={`editable-text__container ${containerClassName}`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {isEditing ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className={`editable-text__form ${formClassName}`}
          >
            <input
              type="text"
              value={editableText}
              onChange={(e) => {
                handleTextChange(e);
              }}
              className={`editable-text__input ${inputClassName}`}
              placeholder={placeholder}
              autoFocus
            />
            {editIcon ? editIcon: <Pencil size={16} className="text-gray-500" />}
          </motion.div>
        ) : (
          <motion.div
            className="flex items-center "
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {children ? (
              children
            ) : (
              <p className={`editable-text__text ${textClassName} mr-2`}>
                {editableText}
              </p>
            )}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default EditableText;
