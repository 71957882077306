import React, { useState, useRef, useEffect } from 'react';

import {
 
    Link,
    Clapperboard,
    ExternalLink,
    FileImage,
    Presentation,
    PanelsTopLeft,
  } from 'lucide-react';
  
const itemsList = [{
    id: 1,
    time: '09:00',
    icon: (
      <Clapperboard
        className="timeline-item__icon"
        size={36}
        strokeWidth={1.75}
        absoluteStrokeWidth
      />
    ),
    title: 'מקרר בהנחה אלקטרה ב20 אחוז ',
    details: 'דגם: elc-154',
    barcode: '345612',
    price: '2000',
    discount: '20',
    finalPrice: '1600',
    category: 'מקררים',
    brand: 'אלקטרה',
    model: 'elc-154',
    supplier: 'אלקטרה',
    text: '• 2 מגירות לפירות וירקות • מדחס אינוורטר BLDC ללא פחמים • יצרן קוביות קרח אוטומטי במקפיא המקרר • פונקציית שבת באישור המכון המדעי הטכנולוגי להלכה • מידות: רוחב: 82 סמ, גובה: 178 סמ, עומק: 77 סמ',
  },
  {
    id: 2,
    time: '11:00',
    icon: (
      <Link
        className="timeline-item__icon"
        size={36}
        strokeWidth={1.5}
        absoluteStrokeWidth
      />
    ),
    title: 'חיסול מלאי כיריים',
    details:
      'Discuss quarterly goals with the team and assign new p terly goals with the team and assign new p terly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new pterly goals with the team and assign new projects.',
  },
  {
    id: 3,
    time: '11:30',
    icon: (
      <FileImage
        className="timeline-item__icon"
        size={36}
        strokeWidth={1.5}
        absoluteStrokeWidth
      />
    ),
    title: 'מחיר מיועד לבאנדל כיריים ותנור',
    details: 'Enjoy a nutritious meal and take a short walk to refresh.',
  },
  {
    id: 4,
    time: '13:00',
    icon: (
      <Presentation
        className="timeline-item__icon"
        size={36}
        strokeWidth={1.5}
        absoluteStrokeWidth
      />
    ),
    title: 'עד 50 אחוז הנחה',
    details:
      'Complete remaining tasks, send follow-up emails, and prepare for tomorrow.',
  },
  {
    id: 5,
    time: '17:00',
    icon: (
      <PanelsTopLeft
        className="timeline-item__icon"
        size={36}
        strokeWidth={1.5}
        absoluteStrokeWidth
      />
    ),
    title: 'מקרן קול בהנחה ברכישת טלוויזיה',
    details:
      'Complete remaining tasks, send follow-up emails, and prepare for tomorrow.',
  }]
export default itemsList;