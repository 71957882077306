/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import './FileUpload.css';
import { getCurrentTime } from '../../helpers/time';
import SendIcon from '@mui/icons-material/Send';
import { formatBytes, removeFileExtension } from '../../helpers/convert';
import CircularProgress from '@mui/material/CircularProgress';
import FilePreview from '../FilePreview/FilePreview';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
function FileUpload({ onSubmit }) {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'flv',
    'avi',
    'avchd',
    'webm',
    'mkv',
  ];
  const [file, setFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fileTimestamp, setFileTimestamp] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState('');
  const [loadingStats, setLoadingStats] = useState({
    isLoading: false,
    progress: 0,
  });
  const acceptedExtensions = [...imageExtensions, ...videoExtensions]
    .map((ext) => `.${ext}`)
    .join(',');
  const resetFileState = () => {
    setFile(null);
    setFilePreviewUrl(null);
    setFileType('');
    setFileTimestamp('');
    setFileSize('');
    setFileName('');
    setLoadingStats({
      isLoading: false,
      progress: 0,
    });
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    console.log(event.target.files[0]);

    setFile(event.target.files[0]);
    let file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setFilePreviewUrl(objectUrl);
      setFileType(file.type);
      setFileTimestamp(getCurrentTime());
      setFileSize(formatBytes(file.size));

      setFileName(removeFileExtension(file.name));
    }
  };

  useEffect(() => {
    // Clean up the object URL when the component unmounts
    return () => {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }
    };
  }, [filePreviewUrl]);

  const handleSubmit = async (e) => {
    if (!file) {
      console.log('Please select a file first.');
      return;
    }

    resetFileState();
    onSubmit({ type: 'file', file }, setLoadingStats);
  };

  return (
    <>
      {loadingStats?.isLoading ? (
        <div className="file-upload__loading">
          <h1 className="file-upload__loading-title">Loading...</h1>
          <div className="file-upload__loading-bar">
            <p className="file-upload-percentage">
              {loadingStats?.progress?.toFixed(0)}%
            </p>

            <CircularProgress
              id="file-upload__CircularProgress"
              className="file-upload__CircularProgress"
            />
          </div>
        </div>
      ) : (
        <div className="buttons__containerx w-full">
          <input
            accept={acceptedExtensions}
            type="file"
            capture="user"
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="raised-button-file"
          />
          <label htmlFor="raised-button-file">
            <Button
              startIcon={<UploadFileIcon fontSize="30px" />}
              variant="contained"
              component="span"
              style={
                fileName
                  ? { backgroundColor: '#FF9800' }
                  : { backgroundColor: '#1976d2' }
              }
            >
              {fileName ? 'שנה קובץ' : 'העלאת קובץ'}
            </Button>
          </label>

          {file && (
            <Button
              variant="contained"
              id="fileUploadSubmitButton"
              style={{ backgroundColor: 'green' }}
              startIcon={
                <SendIcon
                  style={{ transform: 'rotate(180deg)' }}
                  fontSize="30px"
                />
              }
              onClick={handleSubmit}
              disabled={!file}
            >
              שלח
            </Button>
          )}
        </div>
      )}

      {filePreviewUrl && (
        <FilePreview
          fileName={fileName}
          fileSize={fileSize}
          fileTimestamp={fileTimestamp}
          filePreviewUrl={filePreviewUrl}
          fileType={fileType}
        />
      )}
    </>
  );
}

export default FileUpload;
