import React, { useState } from 'react';
import { Upload, Link, AlertCircle } from 'lucide-react';
import { detectFileType } from '../../helpers/detection';
import FileUpload from '../FileUpload/FileUpload';
import './AddSource.css';
const AddSource = ({ onSubmit }) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const validateUrl = (value) => {
    if (!value) {
      setError('נא להזין כתובת URL');
    } else if (!/^https?:\/\/.+/.test(value)) {
      setError('כתובת URL חייבת להתחיל ב-http:// או https://');
    } else if (
      !/\.(jpg|jpeg|png|gif|bmp|webp|mp4|mov|wmv|flv|avi|avchd|webm|mkv)$/i.test(
        value
      )
    ) {
      setError(`הקישור חייב להסתיים בסיומת של קובץ וידאו או תמונה.
לדוגמה: mp4/avi/jpg/png/webp/gif ועוד`);
    } else {
      setError('');
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setUrl(value);
    validateUrl(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!error) {
      const result = detectFileType(url);
      onSubmit({
        type: result.type,
        extension: result.extension,
        url: result.url,
      });
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mx-auto rtl">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-center mb-2">הוספת מדיה</h2>
        <p className="text-sm text-gray-500 text-center">
          הוסף וידאו/תמונה על ידי הזנת קישור או העלאת קובץ לשרת
        </p>
      </div>

      <form onSubmit={handleSubmit} className="mb-6">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row-reverse items-center space-x-reverse space-x-2">
            <input
              type="url"
              placeholder="הכנס קישור"
              value={url}
              onChange={handleUrlChange}
              required
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-right"
            />
            <button
              type="submit"
              className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
              aria-label="שלח קישור"
              disabled={!url.trim() || error}
            >
              <Link className="h-5 w-5" />
            </button>
          </div>
          {error && (
            <div className="form__text_type_error">
              <AlertCircle className="h-4 w-4 ml-1 text-red-500" />
              <span
                style={{ direction: 'rtl' }}
                className="text-red-500 text-sm flex items-center justify-end rtl whitespace-pre-line"
              >
                {error}
              </span>
            </div>
          )}
        </div>
      </form>

      <div className="relative my-6">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-white px-2 text-gray-500">או</span>
        </div>
      </div>

      <div className="mt-6 ">
        <FileUpload onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default AddSource;
