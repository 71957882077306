/*eslint-disable*/
import React, { useState } from 'react';
import DeviceItem from '../DeviceItem/DeviceItem';
import './DeviceList.css';
import { useEffect } from 'react';
import Preloader from '../../Preloader/Preloader';
import PagePreloader from '../../PagePreloader/PagePreloader';
const DeviceList = ({
  onDeleteDevice,
  onItemClick,
  onBookmarkDevice,
  onItemSettings,
  deviceType,
  deviceList,
  onShowStreamClick,
  onVerifyDevice,
  onSelectedItem,
}) => {
  useEffect(() => {
    console.log('deviceList', deviceList);
  }, [deviceType]);
  if (deviceList.length === 0) {
    return <span>אין מכשירים</span>;
  }
  return (
    <div className="device-list">
      <ul className="device-list-ul">
        {deviceList.map((device) => (
          <DeviceItem
            key={device._id}
            data={device}
            deviceType={deviceType}
            onDeleteDevice={onDeleteDevice}
            onSettings={onItemSettings}
            onBookmarkDevice={onBookmarkDevice}
            onClick={onItemClick}
            onVerifyDevice={onVerifyDevice}
            onShowStreamClick={onShowStreamClick}
            onSelectedItem={onSelectedItem}
          />
        ))}
      </ul>
    </div>
  );
};

export default DeviceList;
