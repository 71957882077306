import React, { useState } from 'react';
import './PopupWindow.css';
import CloseIcon from '@mui/icons-material/Close';

const PopupWindow = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
         
<CloseIcon sx={{color:"red"}} onClick={onClose} className='close-button' />
      
        {children}
      </div>
    </div>
  );
};
export default PopupWindow;