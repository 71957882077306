/* eslint-disable */
import React, { useState, useEffect, Suspense, lazy, useContext } from 'react';
import ServerOfflineError from '../utils/errors/ServerOfflineError.js';
import { API_URL } from '../constants/config.js';

class MainApi {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = { headers: headers };
  }

  customFetch = async (url, options = {}) => {
    try {
      const token = localStorage.getItem('jwt');
      const defaultOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      };

      const mergedOptions = {
        ...defaultOptions,
        ...options,
        headers: {
          ...defaultOptions.headers,
          ...options.headers,
        },
      };

      const response = await fetch(url, mergedOptions);

      if (!response.ok) {
        const { message, name } = await response.json();
        const { status } = response;
        const error = new Error(message);
        error.status = status;
        error.name = name;
        throw error;
      }

      return await response.json();
    } catch (error) {
      if (
        error.message.includes('Failed to fetch') ||
        error.message.includes('NetworkError') ||
        error.message.includes('ERR_CONNECTION_REFUSED')
      ) {
        console.error('The server appears to be offline:', error.message);
        throw new ServerOfflineError();
      }
      console.error('Fetch error:', error);
      throw error;
    }
  };

  signin = (data) => {
    return this.customFetch(`${this._baseUrl}/signin`, {
      method: 'POST',
      headers: this._headers.headers,
      body: JSON.stringify(data),
    });
  };

  signup = (data) => {
    return this.customFetch(`${this._baseUrl}/signup`, {
      method: 'POST',
      headers: this._headers.headers,
      body: JSON.stringify(data),
    });
  };

  getUserInfo() {
    return this.customFetch(`${this._baseUrl}/users/me`, this._headers);
  }
  createAssetsResource = (endpoint, formData) => {
    console.log('createResource', endpoint, formData);
    let urlPath = ``;
    if (endpoint === 'url') {
      urlPath = `${this._baseUrl}/assets/url`;
    } else if (endpoint === 'template') {
      urlPath = `${this._baseUrl}/templates`;
    }
    return this.customFetch(urlPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  };

  verifyAndAddDevice = (id, formData) => {
    return this.customFetch(`${this._baseUrl}/qr/add-device/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  };

  getAllDevices = () => {
    return this.customFetch(`${this._baseUrl}/devices`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };
  // check this
  fetchDevice2 = (id) => {
    if (!id) return;
    return this.customFetch(`${this._baseUrl}/devices/${id}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  getPayngoItemInfo = (barcode) => {
    return this.customFetch(`${this._baseUrl}/devices/barcode`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ barcode }),
    });
  };

  checkDeviceAuthentication = (device_id) => {
    return this.customFetch(`${this._baseUrl}/qr/verify-device`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ device_id }),
    });
  };

  deleteDevice = (id) => {
    return this.customFetch(`${this._baseUrl}/devices/${id}`, {
      method: 'DELETE',
      headers: this._headers.headers,
    });
  };
  bookmarkDevice = (id) => {
    return this.customFetch(`${this._baseUrl}/devices/bookmark/${id}`, {
      method: 'POST',
      headers: this._headers.headers,
    });
  };
  registerDevice = () => {
    return this.customFetch(`${this._baseUrl}/qr/register-device`, {
      method: 'POST',
      headers: this._headers.headers,
    });
  };

  uploadFile = (file, onProgress) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      throw new Error('No JWT token found');
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${API_URL}/assets/file`);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.withCredentials = true;

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
          onProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          resolve({ ok: true, response: JSON.parse(xhr.response) });
        } else {
          reject(new Error(`Error uploading file: ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Error uploading file'));
      };

      xhr.send(formData);
    });
  };

  fetchAssets = (params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return this.customFetch(`${this._baseUrl}/assets?${queryString}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  fetchAssetById = (id) => {
    if (!id) return;
    return this.customFetch(`${this._baseUrl}/assets/${id}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  searchAssets = (query) => {
    return this.customFetch(`${this._baseUrl}/assets/search?q=${query}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  fetchTemplates = (params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return this.customFetch(`${this._baseUrl}/templates?${queryString}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  fetchTemplateById = (id) => {
    if (!id) return;
    return this.customFetch(`${this._baseUrl}/templates/${id}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  fetchRelatedAssets = (templateId) => {
    if (!templateId) return;
    return this.customFetch(`${this._baseUrl}/templates/${templateId}/assets`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  searchTemplates = (query) => {
    return this.customFetch(`${this._baseUrl}/templates/search?q=${query}`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };
  fetchFeaturedContent = () => {
    return this.customFetch(`${this._baseUrl}/featured-content`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  fetchAnalytics = () => {
    return this.customFetch(`${this._baseUrl}/analytics`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  deleteAsset = (id) => {
    return this.customFetch(`${this._baseUrl}/assets/${id}`, {
      method: 'DELETE',
      headers: this._headers.headers,
    });
  };
  deleteTemplate = (id) => {
    return this.customFetch(`${this._baseUrl}/templates/${id}`, {
      method: 'DELETE',
      headers: this._headers.headers,
    });
  };

  getPlaylistByDeviceId = (device_id) => {
    return this.customFetch(`${this._baseUrl}/devices/${device_id}/playlist`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  };

  getAllPlaylist = () => {
    return this.customFetch(`${this._baseUrl}/playlists`, {
      method: 'GET',
      headers: this._headers.headers,
    });
  };

  assignPlaylistToDevice = (device_id, playlist_id) => {
    return this.customFetch(`${this._baseUrl}/devices/${device_id}/playlist`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ playlist_id }),
    });
  };

  deletePlaylistItem = (playlistId, itemId) => {
    return this.customFetch(`${this._baseUrl}/playlists/${playlistId}/items/${itemId}`, {
      method: 'DELETE',
      headers: this._headers.headers,
    });
  };

  deletePlaylistById = (playlistId) => {
    return this.customFetch(`${this._baseUrl}/playlists/${playlistId}`, {
      method: 'DELETE',
      headers: this._headers.headers,
    });
  }
  updatePlaylist = (playlistId, playlistData) => {
    return this.customFetch(`${this._baseUrl}/playlists/${playlistId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(playlistData),
    });
  };

  reorderPlaylistItems = (playlistId, items) => {
    return this.customFetch(`${this._baseUrl}/playlists/${playlistId}/reorder`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items }),
    });
  };
  
  createPlaylist = (playlistData) => {
    return this.customFetch(`${this._baseUrl}/playlists`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(playlistData),
    });
  };  
  addPlaylistItem = (playListId, itemData) => {
    return this.customFetch(
      `${this._baseUrl}/playlists/${playListId}/add-item`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData),
      }
    );
  };
}

const mainApi = new MainApi({
  baseUrl: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default mainApi;
