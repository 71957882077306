import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FullPageActionCards from '../FullPageActionCards/FullPageActionCards';
import DeviceSettings from '../Device/DeviceSettings/DeviceSettings';
import MainApi from '../../utils/MainApi';
import PlaylistAddItemForm from '../PlaylistAddItemForm/PlaylistAddItemForm';
import { ArrowRight } from 'lucide-react';
import './ItemsModal.css';
import MediaFilterComponent from '../MediaFilterComponent/MediaFilterComponent';
import {
  PlusCircle,
  Edit,
  Trash2,
  Film,
  Image,
  FileText,
  Save,
  Hourglass,
  ArrowDownUp,
  IdCard,
  FileType2,
} from 'lucide-react';
import RecentItemCard from '../RecentItemCard/RecentItemCard';
import EditableText from '../EditableText/EditableText';
import './ItemsModal.css'; // Make sure to create this CSS file
import Modal from '../Modal/Modal';
const ItemTypeIcon = ({ itemType }) => {
  switch (itemType) {
    case 'video':
      return <Film className="icon icon-video" />;
    case 'image':
      return <Image className="icon icon-image" />;
    default:
      return <FileText className="icon icon-file" />;
  }
};

const ItemsModal = ({
  isOpen,
  onClose,
  items,
  onDeleteItem,
  onEditItem,
  onAddItem,
  onSaveOrder,
  onUploadSubmit,
  onPayngoItemFetch,
  playlistId,
}) => {
  const [localItems, setLocalItems] = useState(items);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [selectedResourceItem, setSelectedResourceItem] = useState(null);
  const [resourceItemsAvailable, setResourceItemsAvailable] = useState([]);
  const [displayStages, setDisplayStages] = useState(0);
  const [stageHistory, setStageHistory] = useState([0]);
  const [isItemInEditMode, setIsItemInEditMode] = useState(false);
  const [itemEdited, setItemEdited] = useState({});
  const [itemEditedId, setItemEditedId] = useState(null);
  useEffect(() => {
    setLocalItems(items);
  }, [items]);
  useEffect(() => {
    if (displayStages !== stageHistory[stageHistory.length - 1]) {
      setStageHistory((prevHistory) => [...prevHistory, displayStages]);
    }
  }, [displayStages]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(localItems);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    // Update the order property for all items
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    setLocalItems(updatedItems);
    setIsOrderChanged(true);
  };

  const handleSaveOrder = () => {
    onSaveOrder(localItems);
    setIsOrderChanged(false);
  };

  const fetchAvailableResources = useCallback(async () => {
    try {
      const [assetsData, templatesData] = await Promise.all([
        MainApi.fetchAssets(),
        MainApi.fetchTemplates(),
      ]);

      setResourceItemsAvailable([
        ...assetsData.results,
        ...templatesData.results,
      ]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    console.log(resourceItemsAvailable);
  }, [resourceItemsAvailable]);

  const handleDeleteItem = async (itemId) => {
    onDeleteItem(playlistId, itemId);

    /*    if (isDeleted) {
      const newItems = localItems.filter((item) => item._id !== itemId);
      setLocalItems(newItems);
    } */
    /*    onItemsChange(playlistId); */
  };

  const handleSubmitAddItem = async (data) => {
    /*    updateDisplayStages(0); */
    const res = await onAddItem(data);
    console.log('res', res);

    updateDisplayStages(0);

    /*   setLocalItems([...localItems, data]); */
  };

  const updateDisplayStages = (newValue) => {
    setDisplayStages(newValue);
  };

  const goBack = () => {
    if (stageHistory.length > 1) {
      const newHistory = stageHistory.slice(0, -1);
      setStageHistory(newHistory);
      setDisplayStages(newHistory[newHistory.length - 1]);
    }
  };
  const handleItemTextChange = (text, name, id) => {
    setItemEdited((prevState) => {
      const updatedItem = {
        ...prevState[id],
        [name]: text,
      };

      return {
        ...prevState,
        [id]: updatedItem,
      };
    });
  };
  useEffect(() => {
    console.log('itemEdited', itemEdited);
  }, [itemEdited]);

  const handleEditItem = (item) => {
    setIsItemInEditMode(true);
    setItemEditedId(item._id);
  };
  const editableTextFormClassname = 'editable-text__form';

  /*   const handleItemTextChange = (text, name, id) => {
    setItemEdited((prevState) => {
      const updatedItem = {
        ...prevState[id],
        [name]: text,
      };

      return {
        ...prevState,
        [id]: updatedItem,
      };
    });
  }; */

  const handleOnUploadsubmit = (data) => {
    onUploadSubmit(data);
    updateDisplayStages(4);
  };

  const renderItems = () => {
    return (
      <>
        <div className="items-actions">
          <button
            onClick={() => {
              updateDisplayStages(2);
            }}
            className="btn btn-add"
          >
            <PlusCircle className="btn-icon" aria-hidden="true" />
            הוסף פריט
          </button>
          {isOrderChanged && (
            <button onClick={handleSaveOrder} className="btn btn-save">
              <Save className="btn-icon" aria-hidden="true" />
              שמור סדר
            </button>
          )}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="item-list"
              >
                {localItems.length === 0 && (
                  <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg shadow-inner">
                    <div className="text-center">
                      <h2 className="text-2xl font-bold text-gray-700 mb-2">
                        אין פריטים ברשימה
                      </h2>
                      <p className="text-gray-500">
                        הוסף פריטים חדשים כדי להתחיל
                      </p>
                    </div>
                  </div>
                )}

                {localItems.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="item"
                        id={item._id}
                      >
                        <div className="item-info">
                          <ItemTypeIcon itemType={item.itemType} />
                          <h3 className="item-name">{item.name}</h3>

                          <div className="item-details">
                            <div className="item-side1">
                              <span className="item-info--text item-type">
                                <FileType2 className="item-info--icon" />
                                {item?.itemType}
                              </span>
                              <span className="item-info--text item-id">
                                <IdCard className="item-info--icon" />
                                {item?._id.slice(-6)}
                              </span>
                            </div>
                            <div className="item-side2">
                              <span className="item-info--text item-order">
                                <ArrowDownUp className="item-info--icon" />
                                {index + 1}
                              </span>
                              <span className="item-info--text item-duration">
                                <Hourglass className="item-info--icon" />
                                {item.duration} שניות
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item-actions">
                          {/*        <Edit
                            className="btn-icon"
                            onClick={() => handleEditItem(item)}
                          />
 */}
                          <Trash2
                            className="btn-icon"
                            onClick={() => handleDeleteItem(item._id)}
                          />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        updateDisplayStages(0);
        onClose();
      }}
      title="ניהול פריטים"
      zIndex={100}
    >
      {displayStages === 0 && renderItems()}
      <div className="displayStages">
        {displayStages > 0 && (
          <button className="icon-back-button" onClick={() => goBack()}>
            <ArrowRight className="icon-back" />
            חזור
          </button>
        )}
        {displayStages === 2 && (
          <FullPageActionCards
            onClickAddExisting={() => {
              fetchAvailableResources();
              updateDisplayStages(4);
            }}
            onClickCreateNew={() => updateDisplayStages(3)}
          />
        )}
        {displayStages === 3 && (
          <DeviceSettings
            onSubmit={handleOnUploadsubmit}
            onPayngoItemFetch={onPayngoItemFetch}
          />
        )}
        {displayStages === 4 && (
          <div className="reactItems">
            <MediaFilterComponent
              mediaItems={resourceItemsAvailable}
              onSelect={(item) => {
                setSelectedResourceItem(item);
                updateDisplayStages(5);
              }}
            />
        {/*     {resourceItemsAvailable.map((item) => (
              <RecentItemCard
                className={'item-card'}
                imgClassName={'item-card__img'}
                cardType={'select'}
                key={item._id}
                item={item}
                type={`${
                  item.hasOwnProperty('templateId') ? 'Template' : 'Asset'
                }`}
              />
            ))} */}
          </div>
        )}

        {displayStages === 5 && (
          <div className="item-card-with-form">
            <PlaylistAddItemForm
              onSubmit={handleSubmitAddItem}
              item={selectedResourceItem}
              type={`${
                selectedResourceItem.hasOwnProperty('templateId')
                  ? 'Template'
                  : 'Asset'
              }`}
            />
          </div>
        )}
      </div>
      {/*       {displayStages === 3 && <CreateNewItem />} */}
    </Modal>
  );
};

export default ItemsModal;
