import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import TemplateMaker from '../../TemplateMaker/TemplateMaker';
import AddSource from '../../AddSource/AddSource';
import { useParams, useNavigate } from 'react-router-dom';
import { Video, Layout } from 'lucide-react';
import { isValidObjectId } from '../../../utils/validateObjectId';

const DeviceSettings = ({
  onSubmit,
  onError,
  onFetchDevice2,
  barcodeData,
  onPayngoItemFetch,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false);
  const [deviceData, setDeviceData] = useState('');
  const [activeTab, setActiveTab] = useState('media');

/*   useEffect(() => {
    if (!isValidObjectId(id)) {
      navigate('/invalid-id', { replace: true });
    }
      onFetchDevice2(); 
  }, [id, navigate,   onFetchDevice2  ]); */

 

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  const TabButton = ({ icon: Icon, label, tabName }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setActiveTab(tabName)}
      className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
        activeTab === tabName 
          ? 'bg-blue-500 text-white' 
          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
      }`}
    >
      <span className="text-2xl">{label}</span>
      <Icon size={28} />
    </motion.button>
  );

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  };

  return (
    <div className="DevicePage p-4 rtl">
      <motion.div 
        className="flex flex-row-reverse space-x-reverse space-x-4 mb-4 bg-white shadow-md rounded-xl p-2"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <TabButton
          icon={Video}
          label="מדיה"
          tabName="media"
        />
        <TabButton
          icon={Layout}
          label="תבנית"
          tabName="template"
        />
      </motion.div>

      <AnimatePresence mode="wait" initial={false}>
        {activeTab === 'template' && (
          <motion.div
            key="template"
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={-1}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <TemplateMaker 
              barcodeData={barcodeData}  
              onPayngoItemFetch={onPayngoItemFetch} 
              onError={onError} 
              onSubmit={onSubmit} 
              data={deviceData} 
            />
          </motion.div>
        )}
        {activeTab === 'media' && (
          <motion.div
            key="media"
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={1}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <AddSource
              onSubmit={onSubmit}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DeviceSettings;